import React from 'react';

import { ContainerTwoColumn, H2 } from '_templates/Sections';
import TrustedServiceIllustration from '_images/svg/illustrations/TrustedService.svg';

import { ContainerGraphic } from './common/Section';

/**
 * Trusted Service From Industry Experts section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const TrustedServiceFromIndustryExperts = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphic>
      <TrustedServiceIllustration />
    </ContainerGraphic>

    <div>
      <H2>Trusted service from industry experts</H2>

      <p>
        Our team brings over 30 years of experience in the merchant services industry, and we pride
        ourselves on offering some of the best and most personable service in the business
      </p>
    </div>
  </ContainerTwoColumn>
);

export default TrustedServiceFromIndustryExperts;
