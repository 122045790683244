import React from 'react';
import { Helmet } from 'react-helmet';

import {
  ContainerPage,
  Margin,
  ContainerNavigation,
  ContainerSection,
  ContainerLandingSection,
  ContainerStartGettingPaid,
  ContainerSectionDivider,
  ContainerFooterDivider,
  ContainerFooter,
} from '_templates/Pages';
import colours from '_theming/colours';
import Curved from '_components/Curved';
import EasyHonestPaymentSolutionsWithNoHiddenFees from '_sections/AboutUs/EasyHonestPaymentSolutionsWithNoHiddenFees';
import Footer from '_sections/Footer';
import HelpFromThoseWhoKnow from '_sections/AboutUs/HelpFromThoseWhoKnow';
import ItPaysToKnowYourOptions from '_sections/AboutUs/ItPaysToKnowYourOptions';
import Layout from '_components/Layout';
import NavigationBar from '_components/NavigationBar';
import ReadyToStartGettingPaid from '_sections/Home/ReadyToStartGettingPaid';
import TrustedServiceFromIndustryExperts from '_sections/AboutUs/TrustedServiceFromIndustryExperts';
import useSiteMetadata from '_hooks/useSiteMetadata';
import WereAlwaysInYourCorner from '_sections/AboutUs/WereAlwaysInYourCorner';

/**
 * About Us page.
 *
 * @param {object} props Component props.
 * @param {object} props.location Router Location object.
 * @returns {React.Component} About Us page.
 */
const AboutUs = ({ location }) => {
  const { siteUrl } = useSiteMetadata();

  const pageUrl = `${siteUrl}${location.pathname}`;
  const title = 'Honest Payment Solutions with No Hidden Fees';
  const description =
    'We offer the most transparent and fair pricing models possible, allowing you to reduce your merchant processing costs so your business can thrive.';
  const imageUrl = `${siteUrl}/images/Coworkers.png`;
  const imageAltText = 'A woman and man discussing merchant processing options';
  const jsonLD = JSON.stringify(
    {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      name: 'About Us',
      description: description,
      breadcrumb: {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@type': 'WebSite',
              '@id': siteUrl,
              name: 'Pricematepay',
            },
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@type': 'WebPage',
              '@id': pageUrl,
              name: 'About',
            },
          },
        ],
      },
      publisher: {
        '@type': 'Organization',
        name: 'Pricematepay',
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}/images/logos/Pricematepay/LightBlue.png`,
        },
        url: siteUrl,
        contactPoint: {
          '@type': 'ContactPoint',
          contactType: 'Sales',
          telephone: '[+1-647-494-3992]',
          email: 'sales@pricematepay.com',
          areaServed: ['CA', 'US'],
          availableLanguage: 'EN',
        },
      },
    },
    null,
    2
  );

  return (
    <Layout>
      <Helmet defer={false}>
        <title>{title} - Pricematepay</title>
        <link rel="canonical" href={pageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta name="og:image:width" content="1200" />
        <meta name="og:image:height" content="630" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image:alt" content={imageAltText} />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" />
        <meta name="description" content={description} />
        <script type="application/ld+json">{jsonLD}</script>
      </Helmet>

      <ContainerPage>
        <ContainerNavigation>
          <Margin>
            <NavigationBar />
          </Margin>
        </ContainerNavigation>

        <ContainerLandingSection bgColor={colours.daintree}>
          <Margin>
            <ItPaysToKnowYourOptions />
          </Margin>
        </ContainerLandingSection>

        <ContainerSectionDivider>
          <Curved preserveAspectRatio="none" fillColor={colours.daintree} />
        </ContainerSectionDivider>

        <ContainerSection bgColor={colours.white} disableTopPadding>
          <Margin>
            <HelpFromThoseWhoKnow />
          </Margin>
        </ContainerSection>

        <ContainerSection bgColor={colours.linkWaterLight}>
          <Margin>
            <TrustedServiceFromIndustryExperts flexDirection="row-reverse" />
          </Margin>
        </ContainerSection>

        <ContainerSection bgColor={colours.white}>
          <Margin>
            <EasyHonestPaymentSolutionsWithNoHiddenFees />
          </Margin>
        </ContainerSection>

        <ContainerSection bgColor={colours.linkWaterLight}>
          <Margin>
            <WereAlwaysInYourCorner />
          </Margin>
        </ContainerSection>

        <ContainerStartGettingPaid bgColor={colours.white}>
          <Margin>
            <ReadyToStartGettingPaid />
          </Margin>
        </ContainerStartGettingPaid>

        <ContainerFooterDivider>
          <Curved preserveAspectRatio="none" fillColor={colours.daintree} />
        </ContainerFooterDivider>

        <ContainerFooter bgColor={colours.daintree}>
          <Margin>
            <Footer />
          </Margin>
        </ContainerFooter>
      </ContainerPage>
    </Layout>
  );
};

export default AboutUs;
