import React from 'react';

import { ContainerOneColumn, H2 } from '_templates/Sections';

/**
 * Help From Those Who Know section.
 *
 * @returns {React.Component} Section.
 */
const HelpFromThoseWhoKnow = () => (
  <ContainerOneColumn>
    <H2>Help from those who know</H2>

    <p>
      When shopping for the best possible rates and fees for your business’s merchant account, it’s
      important to have someone in your corner that has seen thousands of different pricing models
      from thousands of different quotes. We get your business the best pricing by understanding
      what acquirers typically offer, and by using our volume-based pricing to ensure your business
      is not treated as a singular entity.
    </p>

    <p>
      Let Pricematepay take the lead when it comes to your merchant services, because we know what
      good pricing looks like.
    </p>
  </ContainerOneColumn>
);

export default HelpFromThoseWhoKnow;
