import React from 'react';

import { ContainerTwoColumn, H2 } from '_templates/Sections';
import EasyHonestPaymentSolutionsIllustration from '_images/svg/illustrations/EasyHonestPaymentSolutions.svg';

import { ContainerGraphic } from './common/Section';

/**
 * Easy Honest Payment Solutions With No Hidden Fees section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const EasyHonestPaymentSolutionsWithNoHiddenFees = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphic>
      <EasyHonestPaymentSolutionsIllustration />
    </ContainerGraphic>

    <div>
      <H2>Easy, honest payment solutions with no hidden fees</H2>

      <p>
        We offer the most transparent and fair pricing models possible, allowing you to reduce your
        merchant processing costs so your business can thrive
      </p>
    </div>
  </ContainerTwoColumn>
);

export default EasyHonestPaymentSolutionsWithNoHiddenFees;
